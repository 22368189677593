<template>
	<client-page>
        <sub-visual
        sh="소통과 참여"
        tabActive="자주묻는 질문"
        bg="/images/sub/visual/sv5.jpg">
        </sub-visual>

        <section class="section">
            <v-container>
                <faq-list />
            </v-container>
        </section>
	</client-page>
</template>

<script>

import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import FaqList from "@/components/client/center/faqs/faq-list.vue";

export default{
	components: {
        ClientPage,
        SubVisual,
		FaqList,
    },
}
</script>
